import React from 'react'
import {aboutimg,abouticon1,abouticon2,abouticon3,abouticon4} from '../../assets/img'

function AboutBestAtLaundry() {
  
  return (
    <div>
      <section className="aboutsec">
        <div className="container">
          <div className="row">
            <div className="col-lg-5 d-none d-md-block">
              <div className="about-img-left aos-init" data-aos="zoom-in-up">
                <img src={aboutimg} className="img-fluid aos-init" alt="" data-aos="zoom-in-up" />
              </div>
            </div>
            <div className="col-lg-7">
              <div className="about-content-right aos-init" data-aos="zoom-in-up">
                <div className="section-title">
                  <h2 className="text-start">Elthrone Park Launderette</h2>
                  <h1>Professional dry cleaning & laundry services in London.</h1>
                  <div className="section-line">
                    <span className='first-line'></span>
                  </div>
                  <div className="section-line small-line">
                    <span className='second-line'></span>
                  </div>
                </div>

                <p>When we first opened our laundry, we wanted to be able to offer the best service in London and wigmore. To be the best, we had to buy the best. So we went ahead and invested in the finest garment cleaning machinery available. We have our own in-house jacket machine, trouser machine, shirt machine and ironing table. We don’t sub-contract anything. Your garments come from you, to us, and back to you.</p>
                <div className="about-icon">
                  <div className="row">
                    <div className="col-lg-6 col-md-6">
                      <div className="media d-flex">
                        <img src={abouticon1} alt="About Icon" />
                        <div className="media-body flex-grow-1">
                          <h5>Cost Effective</h5>
                          <p>Elthrone Park Launderette provides the laundry services with affordable cost to all when compared it on market.   </p>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-6">
                      <div className="media d-flex">
                        <img src={abouticon2} alt="About Icon" />
                        <div className="media-body flex-grow-1">
                          <h5>Ease to Use</h5>
                          <p>Simple and ease to getting our services and enjoy.</p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-lg-6 col-md-6">
                      <div className="media d-flex">
                        <img src={abouticon3} alt="About Icon" />
                        <div className="media-body flex-grow-1">
                          <h5>Fast Delivery</h5>
                          <p>Our team will collect and deliver your cloth items quickly with free of charge.</p>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-6">
                      <div className="media d-flex">
                        <img src={abouticon4} alt="About Icon" />
                        <div className="media-body flex-grow-1">
                          <h5>Quality of Service</h5>
                          <p>Our team will perfectly wash, dry clean, iron, fold, pack and return to you.</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div></section>
    </div>
  )
}

export default AboutBestAtLaundry